<template>
  <div class="preloader-container">
    <PreloaderIcon width="200" height="200"/>
  </div>
</template>

<script>
import PreloaderIcon from '@/assets/icons/preloader.svg';

export default {
  name: "Preloader",
  components: {
    PreloaderIcon
  }
}
</script>

<style scoped>

</style>
